import { useEffect } from "react";
import { Link } from "react-router-dom";

function Header({currentPageForHeader}) {

    useEffect(() => {
        document.querySelector(".mobile-icon").addEventListener("click", () => {
            if(document.querySelector("header").classList.contains("active")) {
                document.querySelector("header").classList.remove("active");
            } else {
                document.querySelector("header").classList.add("active");
            }
        })
        if(window.innerWidth < 992) {
            [...document.querySelectorAll("header nav ul li")].map((item) => {
                item.addEventListener("click", () => {
                    document.querySelector("header").classList.remove("active");
                });
                return null;
            });
        }
    }, []);

    return (
        <header>
            <a href="/" className="logo">Piotr Thel<br /><span className="logo-description">Muzyka Kościelna</span></a>
            <nav>
                <ul>
                    <li><Link to="/" className={currentPageForHeader === "home" ? 'active' : ''}>Strona Główna</Link></li>
                    <li><Link to="/o-mnie/" className={currentPageForHeader === "about" ? 'active' : ''}>O mnie</Link></li>
                    <li><Link to="/nagrania/" className={currentPageForHeader === "recordings" ? 'active' : ''}>Nagrania</Link></li>
                    <li><Link to="/nuty/" className={currentPageForHeader === "scores" ? 'active' : ''}>Nuty</Link></li>
                    <li><Link to="/artykuly/" className={currentPageForHeader === "articles" ? 'active' : ''}>Artykuły</Link></li>
                    <li><Link to="/biblioteka/" className={currentPageForHeader === "library" ? 'active' : ''}>Biblioteka</Link></li>
                    <li><Link to="/kontakt/" className={currentPageForHeader === "contact" ? 'active' : ''}>Kontakt</Link></li>
                    <li className="menu-icon-li"><a href="https://www.youtube.com/user/pthel90"><img src="/img/yt.svg" className="menu-icon" alt="YouTube" /></a></li>
                </ul>
            </nav>
            <div className="mobile-icon-container">
                <div className="mobile-icon">
                    <div className="line1"></div>
                    <div className="line2"></div>
                    <div className="line3"></div>
                    <div className="line4"></div>
                    <div className="line5"></div>
                </div>
            </div>
        </header>
    );
}

export default Header;