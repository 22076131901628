import { Link } from "react-router-dom";

function Footer() {

    const year = new Date().getFullYear();

    return (
        <>
            <div className="footer-space-container"></div>
            <footer>
                <ul>
                    <li><Link to="/">Strona Główna</Link></li>
                    <li><Link to="/o-mnie/">O mnie</Link></li>
                    <li><Link to="/nagrania/">Nagrania</Link></li>
                    <li><Link to="/nuty/">Nuty</Link></li>
                    <li><Link to="/artykuly/">Artykuły</Link></li>
                    <li><Link to="/biblioteka/">Biblioteka</Link></li>
                    <li><Link to="/kontakt/">Kontakt</Link></li>
                    <li className="menu-icon-li"><a href="https://www.youtube.com/user/pthel90"><img src="/img/yt.svg" className="menu-icon" alt="YouTube" /></a></li>
                </ul>
                <p>&copy; Piotr Thel 2018-{year}</p>
            </footer>
        </>
    );
}

export default Footer;