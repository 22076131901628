import { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import parse from 'html-react-parser';
import './styles/home.css';

function Home({videoFrame, setVideoframe, settingCurrentPageForHeader}) {

    function videoFrameInit(code) {
        setVideoframe(true, code);
    }
    
    useEffect(()=> {
        settingCurrentPageForHeader("home");
    }, [settingCurrentPageForHeader]);

    const [homeState, setHomeState] = useState({
        pageContent : null,
        recordings : null,
        scores : null,
        articles : null,
        error : <p className="text-center">Ups! Błąd aplikacji</p>,
        status : false
    });

    const urls = useMemo(() => {
        return [
        'https://piotrthel.vxm.pl/wp/wp-json/wp/v2/pages/2?acf_format=standard', 
        'https://piotrthel.vxm.pl/wp/wp-json/wp/v2/recordings?per_page=6', 
        'https://piotrthel.vxm.pl/wp/wp-json/wp/v2/scores?acf_format=standard&_embed', 
        'https://piotrthel.vxm.pl/wp/wp-json/wp/v2/articles?per_page=4&_embed'
    ]}, []);
    const promises = useMemo(() => { return [] }, []);
    const allData = useMemo(() => { return [] }, []);
    useEffect(() => {
        if(!homeState.status) {
            urls.map((item) => {
                promises.push(
                    axios.get(item)
                );
                return null;
            });
            Promise.all(urls).then(() => {
                promises.map((item, index) => {
                    item.then(value => {
                        allData[index] = value.data;
                    });
                    return null;
                });
                Promise.all(promises).then(() => {
                    setHomeState({
                        pageContent : allData[0],
                        recordings : allData[1],
                        scores : allData[2],
                        articles : allData[3],
                        status : true
                    });
                    if(document.querySelector(".loading")) {
                        document.querySelector(".loading").remove();
                    }
                });
            })
        }
    }, [homeState, allData, promises, urls]);

    useEffect(()=> {
        if(homeState.status) {
            let currentHomeSlide = 0;
            const homeSlider = [...document.querySelectorAll(".home-slider-img")];
            setInterval(() => {
                let prevCurrentHomeSlide = currentHomeSlide;
                setTimeout(() => {
                    homeSlider[prevCurrentHomeSlide].classList.remove("anim");
                }, 900);
                homeSlider[currentHomeSlide].classList.remove("active");
                currentHomeSlide++;
                if(currentHomeSlide >= homeSlider.length) {
                    currentHomeSlide = 0;
                }
                homeSlider[currentHomeSlide].classList.add("active");
                homeSlider[currentHomeSlide].classList.add("anim");
            }, 5000);

            document.querySelector(".home-slider-arrow").addEventListener("click", () => {
                window.scrollTo({
                    top: window.innerHeight - document.querySelector("header").offsetHeight,
                    behavior: "smooth"
                });
            });
        }
    }, [homeState.status]);

    function newestVideos(data) {
        let videos = [];
        data.map((item, index) => {
            videos.push(
                <div className="card" key={index}>
                    <div className="card-box">
                        <img src={`https://i.ytimg.com/vi/${item.acf.kod_youtube}/mqdefault.jpg`} alt={item.acf.kod_youtube} />
                        <p>{parse(item.title.rendered)}</p>
                        <a href="/#" onClick={ (event) => {event.preventDefault();videoFrameInit(item.acf.kod_youtube);} }>Zobacz</a>
                    </div>
                </div>
            );
            return null;
        });
        return videos;
    }

    function showScores(data) {
        let scores = [];
        function shuffleArr(array) {
            for (var i = array.length - 1; i > 0; i--) {
                var rand = Math.floor(Math.random() * (i + 1));
                [array[i], array[rand]] = [array[rand], array[i]]
            }
            return array;
        }
        shuffleArr(data).slice(0,4).map((item, index) => {
            scores.push(
                <div className="home-notes-box" key={index}>
                    <div className="home-notes-mask">
                        <img src={item.acf.podglad.sizes["medium_large"]} alt={parse(item.title.rendered)} />
                        <div className="overlay"></div>
                    </div>
                    <div className="desc">
                        {parse(item.title.rendered)}<br />
                        <span>
                            {item._embedded["wp:term"][0][0].acf.short_label}
                        </span>
                    </div>
                </div>
            );
            return null;
        });
        return scores;
    }

    function newestArticles(data) {
        let articles = [];
        data.map((item, index) => {
            articles.push(
                <div className="article-box" key={index}>
                    <div className="article-inner-box">
                        <img src={item._embedded["wp:featuredmedia"][0].media_details.sizes.medium_large.source_url} alt={parse(item.title.rendered)} />
                        <h4>{parse(item.title.rendered)}</h4>
                        <Link to={`/artykuly/${item.slug}`}>{homeState.pageContent.acf.articles_link}</Link>
                    </div>
                </div>
            );
            return null;
        });
        return articles;
    }

    function showHomePage(data) {
        let homePageContent = null;
        if(data.status) {
            homePageContent = <>
                <div className="home-slider">
                    <div className="home-slider-img active anim" style={{ backgroundImage: 'url(' + data.pageContent.acf.banner_image_1 + ')'}}></div>
                    <div className="home-slider-img" style={{ backgroundImage: 'url(' + data.pageContent.acf.banner_image_2 + ')'}}></div>
                    <div className="home-slider-img" style={{ backgroundImage: 'url(' + data.pageContent.acf.banner_image_3 + ')'}}></div>
                    <div className="home-slider-overlay"></div>
                    <div className="home-slider-psalm">
                        {data.pageContent.acf.banner_motto}<br />
                        <span>{data.pageContent.acf.banner_motto_2}</span>
                    </div>
                    <div className="home-slider-arrow"><img src="/img/arrow.svg" alt="To content" /></div>
                </div>
                <div className="standard-padding home-videos">
                    <div className="container">
                        <div className="home-videos-text">
                            <div>
                                <h2>
                                    {data.pageContent.acf.recordings_title}
                                </h2>
                                {data.pageContent.acf.recordings_desc}
                            </div>
                            <div className="btn-container">
                                <Link to="/nagrania" className="btn">{data.pageContent.acf.recordings_button} <img src="/img/arrow.svg" alt=">" /></Link>
                            </div>
                        </div>
                        <div className="home-videos-boxes">
                            {newestVideos(data.recordings)}
                        </div>
                    </div>
                </div>
                <div className="standard-padding home-notes">
                    <div className="container">
                        <div>
                            <div className="home-notes-container">
                                {showScores(data.scores)}
                            </div>
                        </div>
                        <div>
                            <h2>
                                {data.pageContent.acf.notes_title}
                            </h2>
                            <p>{parse(data.pageContent.acf.notes_desc)}</p>
                            <div className="btn-container text-center">
                                <Link to="/nuty" className="btn">{data.pageContent.acf.notes_button} <img src="/img/arrow.svg" alt=">" /></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="standard-padding home-articles">
                    <div className="container">
                        <h2>
                            {data.pageContent.acf.articles_title}
                        </h2>
                        <div className="newest-articles">
                            {newestArticles(data.articles)}
                        </div>
                        <div className="text-center">
                            <Link to="/artykuly" className="btn">{data.pageContent.acf.articles_button} <img src="/img/arrow.svg" alt=">" /></Link>
                        </div>
                    </div>
                </div>
                <div className="home-library standard-padding" style={{backgroundImage: 'url(' + data.pageContent.acf.library_img_bg + ')'}}>
                    <div className="overlay"></div>
                    <div className="container">
                        <h2>
                            {data.pageContent.acf.library_title}
                        </h2>
                        {parse(data.pageContent.acf.library_desc)}
                        <div className="btn-box">
                            <Link to="/biblioteka" className="btn">{data.pageContent.acf.library_button} <img src="/img/arrow.svg" alt=">" /></Link>
                        </div>
                    </div>
                </div>
                </>
            ;
        } else {
            homePageContent = data.error;
        }
        return homePageContent;
    }

    return (
        <main>
            <div className="loading fullscreen-loading">
                <img src="/img/loader.png" alt="Wczytywanie..." />
            </div>
            {showHomePage(homeState)}
        </main>
    );
}

export default Home;