import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useState } from 'react';
import GlobalFonts from './fonts/fonts';
import './styles/style.css';
import Header from './Header';
import Footer from './Footer';
import Home from './Home';
import About from './About';
import Recordings from './Recordings';
import Articles from './Articles';
import Article from './Article';
import Notes from './Notes';
import Library from './Library';
import Contact from './Contact';
import VideoFrame from './VideoFrame';
import ImageFrame from './ImageFrame';

function App() {

  // Current page 
  const [currentPageForHeader, setcurrentPageForHeader] = useState("home");
  function settingCurrentPageForHeader(item) {
    setcurrentPageForHeader(item);
  }

  // Video Frame 
  const [videoFrame, setVideoframe] = useState({
    active: false,
    code: '',
  });
  function settingVideoFrame(mode, ytCode) {
    setVideoframe({
      active: mode,
      code: ytCode,
    });
  }

  // Image Frame
  const [imageFrame, setImageFrame] = useState({
    active: false,
    src: '',
  });
  function settingImageFrame(mode, sourceUrl) {
    setImageFrame({
      active: mode,
      src: sourceUrl,
    });
  }

  return (
    <BrowserRouter>
      <GlobalFonts />
      <Header currentPageForHeader={currentPageForHeader}/>
        <Routes>
          <Route exact path="/" element={<Home videoFrame={videoFrame} setVideoframe={settingVideoFrame} settingCurrentPageForHeader={settingCurrentPageForHeader} />}></Route>
          <Route exact path="*" element={<Home  videoFrame={videoFrame} setVideoframe={settingVideoFrame} settingCurrentPageForHeader={settingCurrentPageForHeader} />}></Route>
          <Route path="/o-mnie" element={<About settingCurrentPageForHeader={settingCurrentPageForHeader} />}></Route>
          <Route path="/nagrania" element={<Recordings videoFrame={videoFrame} setVideoframe={settingVideoFrame} settingCurrentPageForHeader={settingCurrentPageForHeader} />}></Route>
          <Route path="/nagrania/:id" element={<Recordings videoFrame={videoFrame} setVideoframe={settingVideoFrame} settingCurrentPageForHeader={settingCurrentPageForHeader} />}></Route>
          <Route exact path="/artykuly" element={<Articles settingCurrentPageForHeader={settingCurrentPageForHeader} />}></Route>
          <Route path="/artykuly/:slug" element={<Article settingCurrentPageForHeader={settingCurrentPageForHeader} />}></Route>
          <Route path="/nuty" element={<Notes imageFrame={imageFrame} setImageFrame={settingImageFrame} settingCurrentPageForHeader={settingCurrentPageForHeader} />}></Route>
          <Route path="/biblioteka" element={<Library settingCurrentPageForHeader={settingCurrentPageForHeader} />}></Route>
          <Route exact path="/kontakt" element={<Contact settingCurrentPageForHeader={settingCurrentPageForHeader} />}></Route>
        </Routes>
      <Footer />
      <VideoFrame videoFrame={videoFrame} setVideoframe={settingVideoFrame} />
      <ImageFrame imageFrame={imageFrame} setImageFrame={settingImageFrame} />
    </BrowserRouter>
  );
}

export default App;

