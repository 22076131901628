import { useEffect, useState } from "react";
import axios from 'axios';
import parse from 'html-react-parser';
import './styles/about.css';

function About({settingCurrentPageForHeader}) {

    useEffect(()=> {
        settingCurrentPageForHeader("about");
    }, [settingCurrentPageForHeader]);

    const [aboutState, setSboutState] = useState({
        content : null,
        status : false
    });

    useEffect(() => {
        axios.get('https://piotrthel.vxm.pl/wp/wp-json/wp/v2/pages/123?acf_format=standard')
        .then(function (response) {
            setSboutState({
                content : response.data,
                status : true
            });
        })
        .catch(function (error) {
            setSboutState({
                content : <p className="text-center">Ups! Błąd aplikacji</p>,
                status : false
            });
        })
        .finally(function () {
            if(document.querySelector(".loading")) {
                document.querySelector(".loading").remove();
            }
        });
    }, []);

    // let posters = [];
    // events.map((item, index) => {
    //     posters.push(
    //         <div className="poster" key={index}>
    //             <a href={`/plakaty/${item.file}.jpg`}>
    //                 <img src={`/plakaty/${item.file}_low.jpg`} alt="" />
    //             </a>
    //         </div>
    //     );
    //     return null;
    // });

    function aboutResults(data) {
        let aboutArray;
        if(data.status) {
            aboutArray =
                <>
                    <div className="about-content">
                        {parse(data.content.content.rendered)}
                    </div>
                    <div className="about-image">
                        <img src={data.content.acf.image} alt={data.content.acf.image_desc} />
                    </div>
                </>;
        } else {
            aboutArray = data.content;
        }
        return aboutArray;
    }

    return (
        <main>
            <div className="menu-space-container"></div>
            <div className="standard-padding about-page">
                <div className="container">
                    <div className="loading"><img src="/img/loader.png" alt="Wczytywanie..." /></div>
                    {aboutResults(aboutState)}
                </div>
            </div>
            {/* <div className="events standard-padding">
                <div className="container">
                    <h2>Wydarzenia i koncerty</h2>
                    <div className="posters">
                        {posters}
                    </div>
                </div>
            </div> */}
        </main>
    );
}

export default About;