import { useEffect, useState } from "react";
import axios from 'axios';
import parse from 'html-react-parser';
import "./styles/library.css";
import select from "./select.js";

function Library({settingCurrentPageForHeader}) {

    useEffect(()=> {
        settingCurrentPageForHeader("library");
    }, [settingCurrentPageForHeader]);

    const [libraryState, setLibraryState] = useState({
        content : null,
        status : false
    });

    useEffect(() => {
        axios.get('https://piotrthel.vxm.pl/wp/wp-json/wp/v2/links')
            .then(function (response) {
                setLibraryState({
                    content : response.data,
                    status : true
                });
            })
            .catch(function (error) {
                setLibraryState({
                    content : <p className="text-center">Ups! Błąd aplikacji</p>,
                    status : false
                });
            })
            .finally(function () {
                if(document.querySelector(".loading")) {
                    document.querySelector(".loading").remove();
                }
            });
    }, []);

    useEffect(() => {
        if(libraryState.status) {
            select();
            document.querySelector(".select-title").classList.add("active");
            document.querySelector(".select-container").classList.add("active");
            document.querySelector(".select-container").style.height = document.querySelector(".select-container").firstElementChild.clientHeight + 'px';
            setTimeout(() => {
                [...document.querySelectorAll(".select-container")].map((item) => {
                    item.style.transition = 'height 0.5s';
                    return null;
                });
            }, 150);
        }
    }, [libraryState.status]);

    function libraryResults(data) {
        let libraryContent = [];
        if(data.status) {
            data.content.map((item, index) => {
                libraryContent.push(<div key={index}>
                    <h3 className={`select-title${index === 0 ? ' active' : ''}`}>{parse(item.title.rendered)} <img src="/img/arrow.svg" alt=">" /></h3>
                    <div className={`select-container${index === 0 ? ' active' : ''}`} style={index === 0 ? {height: '0px', transition: 'none'} : {height: '0px'}}>
                        {parse(item.content.rendered)}
                    </div>
                </div>);
                return null;
            });
        } else {
            libraryContent.push(data.content);
        }
        return libraryContent;
    }

    return (
        <main>
            <div className="menu-space-container"></div>
            <div className="standard-padding">
                <div className="container">
                    <div className="loading"><img src="/img/loader.png" alt="Wczytywanie..." /></div>
                    {libraryResults(libraryState)}
                    {/* {initSelect(libraryState)} */}
                </div>
            </div>
        </main>
    );
}

export default Library;