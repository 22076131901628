import './styles/videoframe.css';

function VideoFrame({videoFrame, setVideoframe}) {

    function close() {
        setVideoframe(false);
    }

    let embedCode;
    if(videoFrame.code === "") {
        embedCode = '';
    } else {
        embedCode = <iframe title="Video preview" className="yt-embed" src={`https://www.youtube.com/embed/${videoFrame.code}`} allow="autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    }

    return (
        <div className={`expand-video${videoFrame.active ? ` active` : ` ` }`}>
            <img src="/img/close.svg" alt="Zamknij" className="expand-close" onClick={close} />
            <div className="expand-video-container">
                {embedCode}
            </div>
        </div>
    )
}

export default VideoFrame;