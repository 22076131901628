import './styles/imageframe.css';

function ImageFrame({imageFrame, setImageFrame}) {

    function close() {
        setImageFrame(false, imageFrame.src);
    }

    return (
        <div className={`expand-image${imageFrame.active ? ` active` : ` ` }`}>
            <img src="/img/close.svg" alt="Zamknij" className="expand-image-close" onClick={close} />
            <div className="expand-image-container">
                <img src={imageFrame.src} alt="" />
            </div>
        </div>
    )
}

export default ImageFrame;