import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import parse from 'html-react-parser';
import "./styles/articles.css";

function Articles({settingCurrentPageForHeader}) {

    useEffect(()=> {
        settingCurrentPageForHeader("articles");
    }, [settingCurrentPageForHeader]);

    const [articlesState, setArticlesState] = useState({
        content : null,
        status : false
    });

    useEffect(() => {
        axios.get('https://piotrthel.vxm.pl/wp/wp-json/wp/v2/articles?per_page=100&_embed')
        .then(function (response) {
            setArticlesState({
                content : response.data,
                status : true
            });
        })
        .catch(function (error) {
            setArticlesState({
                content : <p className="text-center">Ups! Błąd aplikacji</p>,
                status : false
            });
        })
        .finally(function () {
            if(document.querySelector(".loading")) {
                document.querySelector(".loading").remove();
            }
        });
    }, []);

    function articlesResults(data) {
        let articlesArray = [];
        if(data.status) {
            data.content.map((item, index) => {
                articlesArray.push(
                    <div className="article-box" key={index}>
                        <div className="article-inner-box">
                            <img src={item._embedded ? item._embedded["wp:featuredmedia"][0].media_details.sizes.medium_large.source_url : ""} alt={item.title.rendered} />
                            <h4>{parse(item.title.rendered)}</h4>
                            <p>{ item.acf.excerpt ? item.acf.excerpt : "" } (...)</p>
                            <Link to={`/artykuly/${item.slug}`}>Zobacz więcej</Link>
                        </div>
                    </div>
                );
                return null;
            });
        } else {
            articlesArray.push(data.content);
        }
        return articlesArray;
    }

    return (
        <main>
            <div className="menu-space-container"></div>
            <div className="standard-padding">
                <div className="container">
                    <div className="articles-container">
                        <div className="loading"><img src="/img/loader.png" alt="Wczytywanie..." /></div>
                        {articlesResults(articlesState)}
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Articles;