import { useEffect, useState } from "react";
import axios from "axios";
import "./styles/contact.css";

function Contact({settingCurrentPageForHeader}) {

    useEffect(()=> {
        settingCurrentPageForHeader("contact");
    }, [settingCurrentPageForHeader]);

    const [contactState, setContactState] = useState({
        content : null,
        status : false
    });

    useEffect(() => {
        axios.get('https://piotrthel.vxm.pl/wp/wp-json/wp/v2/pages/160?acf_format=standard')
        .then(function (response) {
            setContactState({
                content : response.data,
                status : true
            });
        })
        .catch(function (error) {
            setContactState({
                content : <p className="text-center">Ups! Błąd aplikacji</p>,
                status : false
            });
        })
        .finally(function () {
            if(document.querySelector(".loading")) {
                document.querySelector(".loading").remove();
            }
        });
    }, []);

    useEffect(() => {
        if(contactState.status) {
            document.querySelector("#contact-form button").addEventListener("click", (event) => {
                event.preventDefault();
                if(document.querySelector(".message-box").firstElementChild) {
                    document.querySelector(".message-box").firstElementChild.remove();
                }
                const url = 'https://piotrthel.vxm.pl/mail/email.php?key=uTpags4RH';
                axios
                    .post(url, {
                        name: document.querySelector("#name").value,
                        mail: document.querySelector("#mail").value,
                        content: document.querySelector("#content").value,
                        source: "piotrthel.vxm.pl"
                    })
                    .then((response) => {
                        document.querySelector(".message-box").insertAdjacentHTML("beforeend", response.data);
                    });
            })
        }
    }, [contactState.status]);

    function contactResults(data) {
        let contactArray;
        if(data.status) {
            contactArray =
                <>
                    <h2>{data.content.acf.form_title}</h2>
                    <form action="" method="post" id="contact-form">
                        <input type="text" id="name" name="imie" className="contact-inputs" placeholder={data.content.acf.placeholder_name} />
                        <input type="text" id="mail" name="poczta" className="contact-inputs" placeholder={data.content.acf.placeholder_mail} />
                        <textarea className="contact-textarea" id="content" placeholder={data.content.acf.placeholder_content} name="tresc"></textarea>
                        <button type="submit" className="btn">{data.content.acf.button_send} <img src="/img/arrow.svg" alt=">" /></button>
                    </form>
                    <div className="message-box"></div>
                    <h2>{data.content.acf.direct_mail}</h2>
                    <h4 className="center">{data.content.acf.e_mail}</h4>
                </>;
        } else {
            contactArray = data.content;
        }
        return contactArray;
    }

    return (
        <main>
            <div className="menu-space-container"></div>
            <div className="standard-padding contact-page">
                <div className="container">
                    <div className="loading"><img src="/img/loader.png" alt="Wczytywanie..." /></div>
                    {contactResults(contactState)}
                </div>
            </div>
        </main>
    );
}

export default Contact;