import { createGlobalStyle } from 'styled-components';

import liberationSerifBold from './LiberationSerif-Bold.woff';
import liberationSerifItalic from './LiberationSerif-Italic.woff';
import liberationSerif from './LiberationSerif.woff';
import NotoSerif from './NotoSerif-Regular.ttf';

export default createGlobalStyle`
    @font-face {
        font-family: 'Liberation Serif';
        src: url(${liberationSerifBold}) format('woff');
        font-weight: bold;
        font-style: normal;
    }

    @font-face {
        font-family: 'Liberation Serif';
        src: url(${liberationSerifItalic}) format('woff');
        font-weight: normal;
        font-style: italic;
    }

    @font-face {
        font-family: 'Liberation Serif';
        src: url(${liberationSerif}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Noto Serif';
        src: url(${NotoSerif}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }
  `;