import { useEffect, useState, useCallback } from 'react';
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import parse from 'html-react-parser';
import './styles/recordings.css';

function Recordings({videoFrame, setVideoframe, settingCurrentPageForHeader}) {

    useEffect(()=> {
        settingCurrentPageForHeader("recordings");
    }, [settingCurrentPageForHeader]);

    const videosPage = useParams();
    const itemsPerPage = 12;
    let currentPage;
    if(videosPage.id === undefined) {
        currentPage = 1;
    } else {
        currentPage = parseFloat(videosPage.id);
    }

    const [videosState, setVideoState] = useState({
        videos : [],
        prevArrow : null,
        nextArrow : null,
        pagination : null,
        status : false
    });

    const prevArrowSet = useCallback((data) => {
        let prevArrow = null;
        if(currentPage > 1) {
            prevArrow = <Link to={`/nagrania/${currentPage - 1}`} className="page-arrow arrow-prev" onClick={loaderInPagination}>Poprzednia strona <img src="/img/arrow.svg" alt="<" /></Link>;
        }
        return prevArrow;
    }, [currentPage]);

    const nextArrowSet = useCallback((data) => {
        let nextArrow = null;
        if(currentPage !== parseFloat(data.headers["x-wp-totalpages"])) {
            nextArrow = <Link to={`/nagrania/${currentPage + 1}`} className="page-arrow arrow-next" onClick={loaderInPagination}>Następna strona <img src="/img/arrow.svg" alt=">" /></Link>;
        }
        return nextArrow;
    }, [currentPage]);

    const paginationSet = useCallback((data) => {
        let pagination = null;
        if(data) {
            pagination = <div className="numbers">{currentPage} / {data.headers["x-wp-totalpages"]}</div>
        }
        return pagination;
    }, [currentPage]);

    useEffect(() => {
        axios.get("https://piotrthel.vxm.pl/wp/wp-json/wp/v2/recordings?per_page=" + itemsPerPage + "&page=" + currentPage)
        .then(function (response) {
            setVideoState({
                videos : response.data,
                prevArrow : prevArrowSet(response),
                nextArrow : nextArrowSet(response),
                pagination : paginationSet(response),
                status : true
            });
        })
        .catch(function (error) {
            setVideoState({
                videos : <p className="text-center">Ups! Błąd aplikacji</p>,
                prevArrow : null,
                nextArrow : null,
                pagination : null,
                status : false
            });
        })
        .finally(function () {
            if(document.querySelector(".loading")) {
                document.querySelector(".loading").remove();
            }
        });
    }, [currentPage, prevArrowSet, nextArrowSet, paginationSet]);

    function videoFrameInit(code) {
        setVideoframe(true, code);
    }

    function loaderInPagination() {
        document.querySelector(".videos-container").insertAdjacentHTML('beforeend', '<div class="loading loading-items"><img src="/img/loader.png" alt="Wczytywanie..." /></div>');
    }

    function createRecordingsList(data) {
        let videosArray = [];
        if(data.status) {
            data.videos.map((item, index) => {
                videosArray.push(
                    <div className="card" key={index}>
                        <div className="card-box">
                            <img src={`https://i.ytimg.com/vi/${item.acf.kod_youtube}/mqdefault.jpg`} alt={item.title} />
                            <p>{parse(item.title.rendered)}</p>
                            <a href="/#" onClick={ (event) => {event.preventDefault();videoFrameInit(item.acf.kod_youtube);} }>Zobacz</a>
                        </div>
                    </div>
                );
                return null;
            });
        } else {
            videosArray = data.videos;
        }
        return videosArray;
    }

    return (
        <main>
            <div className="menu-space-container"></div>
            <div className="container standard-padding">
                <div className="videos-container">
                    <div className="loading loading-items"><img src="/img/loader.png" alt="Wczytywanie..." /></div>
                    {createRecordingsList(videosState)}
                </div>
                <div className="videos-nav">
                    {videosState.prevArrow}
                    {videosState.pagination}
                    {videosState.nextArrow}
                </div>
            </div>
        </main>
    );
}

export default Recordings;