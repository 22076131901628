function select() {
    window.addEventListener("resize", () => {
        [...document.querySelectorAll(".select-container")].map((item, index) => {
            if(item.classList.contains("active")) {
                item.style.height = item.firstElementChild.clientHeight + 'px';
            }
            return null;
        });
    });

    [...document.querySelectorAll(".select-title")].map((item, index) => {
        item.addEventListener("click", (event) => {
            if(event.target.classList.contains("active")) {
                event.target.classList.remove("active");
                event.target.nextElementSibling.classList.remove("active");
                event.target.nextElementSibling.style.height = '0px';
            } else {
                event.target.classList.add("active");
                event.target.nextElementSibling.classList.add("active");
                event.target.nextElementSibling.style.height = event.target.nextElementSibling.firstElementChild.clientHeight + 'px';
            }
        })
        return null;
    });
}

export default select;